.tick {
    /* user configurable when they click on the tick */
    font-size: 2.5em;
}
@font-face {
    font-family: Linotte;
    src: url(../../static/linotte-semibold.woff2);
}
.counter-header {
    margin-bottom: 1rem;
    position: absolute;
    bottom: 0.125rem;
    font-size: 2rem;
    float: left;
    font-weight: 400;
    line-height: 1;
    color: #3a3c3f;
    font-family: Linotte,linotte-semibold,Clear Sans,clearsans-regular,sans-serif;
    /*text-transform: lowercase;*/
}

.counter-viewer-background {
    background: linear-gradient(0deg,#b8bab9,hsla(150,1%,73%,0));
}
